import './App.css';
import React from "react";
import pako from "pako";

class Game extends React.Component {
	constructor(props){
  	super(props);
    this.state = { messages : [], board: [] }
    this.inp = null;
  }

  componentDidMount(){
    let host = process.env["REACT_APP_LOCAL_SERVER"] ? "ws://localhost:3000" : "wss://osw.rutgerdj.net";

    this.connection = new WebSocket(`${host}/api/ws`);
    this.connection.binaryType = "arraybuffer";

    this.connection.onmessage = (evt) => {
      let strData = evt.data;
      if (evt.data instanceof ArrayBuffer) {
        // binary frame
        const view = new DataView(evt.data);
        var binData     = new Uint8Array(view.buffer);
        var data        = pako.inflate(binData);
        strData     = String.fromCharCode.apply(null, new Uint16Array(data));

      }

      if (strData[0] !== "{" && strData[0] !== "[") {
        this.setState({
          messages : this.state.messages.concat([ evt.data ])
        })
      } else {
        let msg = JSON.parse(strData);
        this.setState({
          board: msg
        })
      }

    };

    this.connection.onopen = () => {
      console.log("connected")
    }

    this.connection.onerror = (x) => {
      console.log(x)
    }

  }

  render() {

    return (
      <div>
        <input ref={x => this.inp =x} type="text"></input>
        <button onClick={() => this.connection.send(this.inp.value)} >Send</button>
        <ul>
        { this.state.messages.slice(-5).map( (msg, idx) => <li key={'msg-' + idx }>{ msg }</li> )}
        </ul>
        <div>
          <table>
            <tbody>
            { this.state.board.map((row, j) =>
              <tr key={`row-${j}`}>
                { row.map((col, i) => <td key={`cell-${j}-${i}`} onClick={() => {
                  this.connection.send(`/gamecmd ${this.inp.value} click ${i} ${j}`)
                }}>
                  {col.state === "hidden" ? "□" : col.cell}
                </td>)}
              </tr>
            ) }
            </tbody>
          </table>
        </div>
      </div>
    );
  }
};


function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Game></Game>
      </header>
    </div>
  );
}

export default App;
